export enum DaysOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export type WeeklyStatisticsModel = {
  [index in DaysOfWeek]: {
    opened: number;
    delivered: number;
  };
};
