import { ActionExecutionInfo } from '@rego-engage/engage-types';

import {
  EmailProgress,
  EmailTemplateInfo,
  LOCALSTORAGE_EMAIL_PROGRESS_KEY,
  LOCALSTORAGE_STATUS_BAR_KEY,
} from './email-status.interface';

const getCurrentDetails = (
  currentStep: string,
  status:
    | 'NOT RUNNING'
    | 'RUNNING'
    | 'SUCCEEDED'
    | 'FAILED'
    | 'TIMED OUT'
    | 'ABORTED',
): string => {
  let details = 'Preparing data';

  if (status === 'SUCCEEDED') {
    return 'Email(s) sent';
  } else if (status === 'FAILED') {
    return 'Not able to send the email';
  }

  switch (currentStep) {
    case 'http-action': {
      details = 'Pulling data from APIs';
      break;
    }
    case 'apptio-email-report-action': {
      return 'Reading template configuration';
    }
    case 'data-processing-action': {
      details = 'Running extractors, populating the design';
      break;
    }
    case 'chart-action': {
      details = 'Generating chart(s)';
      break;
    }
    case 'email-action': {
      details = 'Preparing email(s)';
      break;
    }
    default: {
      details = 'Preparing data';
      break;
    }
  }
  return details;
};

const getCurrentProgress = (
  currentStep: string,
  status:
    | 'NOT RUNNING'
    | 'RUNNING'
    | 'SUCCEEDED'
    | 'FAILED'
    | 'TIMED OUT'
    | 'ABORTED',
): number => {
  let progress = 0;

  if (status === 'SUCCEEDED' || status === 'FAILED') {
    return 100;
  }

  switch (currentStep) {
    case 'http-action': {
      progress = 0;
      break;
    }
    case 'data-processing-action': {
      progress = 25;
      break;
    }
    case 'chart-action': {
      progress = 50;
      break;
    }
    case 'email-action': {
      progress = 75;
      break;
    }
    default: {
      progress = 0;
      break;
    }
  }
  return progress;
};

export const openStatusBar = () => {
  localStorage.setItem(LOCALSTORAGE_STATUS_BAR_KEY, 'open');
};

// FIXME: Move this into redux state with linkage to localstorage
export const saveEmailProgress = (
  executionId: string,
  executionInfo: Pick<ActionExecutionInfo, 'currentStep' | 'status' | 'error'>,
  templateInfo: EmailTemplateInfo | undefined,
) => {
  try {
    const MAX_AMOUNT_OF_EMAILS = 5;
    const currentProgressLS = localStorage.getItem(
      LOCALSTORAGE_EMAIL_PROGRESS_KEY,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let currentProgress: EmailProgress[] = currentProgressLS
      ? JSON.parse(currentProgressLS)
      : [];

    if (currentProgress.length === MAX_AMOUNT_OF_EMAILS) {
      currentProgress = currentProgress.filter(
        execution => execution.progress < 100,
      );
    }

    const existsInStorageIndex = currentProgress.findIndex(
      progress => progress.id === executionId,
    );

    if (existsInStorageIndex <= -1) {
      currentProgress.push({
        id: executionId,
        title: templateInfo?.name ?? executionId,
        details:
          executionInfo.error ??
          getCurrentDetails(executionInfo.currentStep, executionInfo.status),
        progress: getCurrentProgress(
          executionInfo.currentStep,
          executionInfo.status,
        ),
        status: executionInfo.status,
        filters: templateInfo?.filters,
        createdAt: new Date().getTime(),
      });
    } else {
      currentProgress[existsInStorageIndex].details = getCurrentDetails(
        executionInfo.currentStep,
        executionInfo.status,
      );
      currentProgress[existsInStorageIndex].progress = getCurrentProgress(
        executionInfo.currentStep,
        executionInfo.status,
      );
      currentProgress[existsInStorageIndex].status = executionInfo.status;
    }

    localStorage.setItem(
      LOCALSTORAGE_EMAIL_PROGRESS_KEY,
      JSON.stringify(currentProgress),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
