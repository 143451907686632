import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { engageApi } from '../services/engage/engage.api';
import { lambdaApi } from '../services/lambda/lambda.api';
import { appStateSlice } from './app-state/app-state.slice';
import { authSlice } from './auth/auth.slice';
import { errorMiddleware } from './error-handling/error-middleware';
import { sessionSlice } from './session/session.slice';
import { uiSlice } from './ui/ui.slice';

export type RootState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;

const appReducer = combineReducers({
  [appStateSlice.name]: appStateSlice.reducer,
  [sessionSlice.name]: sessionSlice.reducer,
  auth: authSlice.reducer,
  [engageApi.reducerPath]: engageApi.reducer,
  [lambdaApi.reducerPath]: lambdaApi.reducer,
  [uiSlice.name]: uiSlice.reducer,
});

const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === 'auth/logout/fulfilled') {
    localStorage.clear();
    sessionStorage.clear();
    state = {} as RootState;
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(engageApi.middleware)
      .concat(lambdaApi.middleware)
      .concat(errorMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

// For refetchOnFocus, refetchOnReconnect
setupListeners(store.dispatch);

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
