import Tooltip from '@mui/joy/Tooltip';
import { skipToken } from '@reduxjs/toolkit/query';
import { ArrowRight, LogOut01, Menu01 } from '@untitled-ui/icons-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import tw from 'tailwind-styled-components';

import appConfig from '../../../config/app.config';
import type { LayoutProps } from '../../route/types';
import { useGetCompanyQuery } from '../../services/engage/company/company.api';
import { useGetUserProfileQuery } from '../../services/engage/user-profile/user-profile.api';
import logger from '../../services/logger';
import SessionExpirationModal from '../../session-management/session-expiration-modal';
import useSessionManagement from '../../session-management/use-session-management';
import {
  useGetCompanyId,
  useMutateCompanyId,
} from '../../store/app-state/app-state.slice';
import { logout } from '../../store/auth/actions';
import { useAppDispatch } from '../../store/store';
import { useMutateNag } from '../../store/ui/ui.slice';
import { UIElements } from '../../store/ui/ui-elements';
import EmailStatusDialog from '../email-status/email-status-dialog';
import CompanySelector from './company-selector';

const SidebarParent = tw.div`
  fixed left-0 inset-y-0 h-screen bg-white transition-all
  border-r border-slate-200 z-10 flex flex-col
`;

const MenuItem = tw.div`
  flex py-1 px-2 bg-white hover:bg-slate-100 text-slate-700
  hover:text-cyan-700 text-sm items-center cursor-pointer
`;

const Layout = (props: LayoutProps) => {
  // Start tracking session expiration
  useSessionManagement();

  const router = useRouter();
  const dispatch = useAppDispatch();

  const selectedCompanyId = useGetCompanyId();
  const mutateSelectedCompany = useMutateCompanyId();
  const { data: userProfile, isLoading: userProfileIsLoading } =
    useGetUserProfileQuery();
  const mutateNag = useMutateNag();

  const [open, setOpen] = useState(true);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  const basePathName = router.pathname.split('/')[1];

  const previousQueryParams = router.query.companyId
    ? { companyId: router.query.companyId }
    : {};

  const isSwitchCompanyAllowed =
    userProfile?.scopes.includes('switch:company') &&
    !['profile', 'companies'].includes(basePathName);

  const userHasScope = (scope: string) => {
    if (userProfile?.roles.includes('admin') || scope == 'all') {
      return true;
    }

    // We don't have scopes for all routes yet
    if (scope == '') {
      return true;
    }

    return userProfile?.scopes.includes(scope);
  };

  useEffect(() => {
    if (userProfile?.scopes.includes('switch:company')) {
      const companyIdFromUrl = Array.isArray(router.query.companyId)
        ? router.query.companyId[0]
        : router.query.companyId;
      if (companyIdFromUrl && companyIdFromUrl !== selectedCompanyId) {
        mutateSelectedCompany(companyIdFromUrl);
      }
    }
  }, [
    userProfile,
    router.query.companyId,
    selectedCompanyId,
    mutateSelectedCompany,
  ]);

  // TODO: Split this off into its own hook.
  const { data: company } = useGetCompanyQuery(
    selectedCompanyId
      ? { companyId: selectedCompanyId }
      : userProfile
        ? { companyId: userProfile.companyId }
        : skipToken,
  );
  useEffect(() => {
    const destiny = '/company-settings';
    const canModify =
      userProfile?.scopes.includes('manage:company') &&
      router.pathname !== destiny;
    if (canModify) {
      const isDefaultEnvSet = company?.defaultEnvironmentId;
      if (!isDefaultEnvSet) {
        mutateNag({
          id: 'no-default-env',
          nag: {
            title: 'No Default Environment Set',
            children: (
              <p>
                Please set a default Apptio environment in order to utilize
                reporting features.
              </p>
            ),
            onClick: () => void router.push(destiny),
            cta: 'Set Default Environment',
          },
        });
      }
    }
    return () => {
      mutateNag({
        id: 'no-default-env',
        nag: undefined,
      });
    };
  }, [company, mutateNag, router, userProfile]);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  // Handle sidebar collapse on resize
  // TODO: This could probably use MUI useMediaQuery
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // TODO: Split this off into its own hook.
  useEffect(() => {
    const verifyPath = '/details/security-access';
    if (
      !userProfile ||
      userProfileIsLoading ||
      router.pathname === verifyPath
    ) {
      mutateNag({ id: 'email-verification', nag: undefined });
      return;
    }
    if (userProfile.isEmailVerified === false) {
      mutateNag({
        id: 'email-verification',
        nag: {
          title: 'Verify your email',
          type: 'warning',
          children: (
            <p>
              Please verify your email address. Lost password recovery is
              disabled until your email has been verified.
            </p>
          ),
          onClick: () => void router.push(verifyPath),
          cta: 'Verify Email',
        },
      });
    } else {
      mutateNag({ id: 'email-verification', nag: undefined });
    }
  }, [userProfile, mutateNag, router, userProfileIsLoading]);

  useMemo(() => {
    const routes = props.routeGroups.filter(item => !item.mainRoute.main);
    const foundRoute = routes.find(
      item =>
        item.mainRoute.path == router.pathname ||
        item.routes.find(r => r.path == router.pathname),
    );
    if (foundRoute) {
      setProfileMenuOpen(true);
    }
  }, [props.routeGroups, router.pathname]);

  const handleLogout = () => {
    dispatch(logout(null)).catch((err: unknown) => logger.error(err));
  };

  const profileInitials = () => {
    if (userProfile?.firstName && userProfile.lastName) {
      return userProfile.firstName[0] + userProfile.lastName[0];
    }
    return 'UN';
  };

  return (
    <>
      <SessionExpirationModal
        countdownStart={appConfig.idleCountdownStart}
        maxIdleTime={appConfig.maxIdleTime}
        onTimeExpired={handleLogout}
      />
      <SidebarParent style={{ width: `${open ? '16rem' : '3.5rem'}` }}>
        <div
          className={`flex-none ${
            open ? 'p-4' : 'px-2 pt-4 pb-2 flex flex-col-reverse'
          }`}
        >
          <div
            className={`flex items-center ${
              open ? 'justify-between' : 'justify-center mt-3'
            }`}
          >
            {open && (
              <img
                src="/assets/images/new-logo.png"
                alt="logo"
                className="w-32"
              />
            )}
            {open ? (
              <Menu01
                className="cursor-pointer text-slate-500 hover:text-cyan-700"
                onClick={() => setOpen(!open)}
              />
            ) : (
              <ArrowRight
                className="cursor-pointer text-slate-500 hover:text-cyan-700"
                onClick={() => setOpen(!open)}
              />
            )}
          </div>
          {!userProfileIsLoading && isSwitchCompanyAllowed && open && (
            <div className="mt-4">
              <CompanySelector />
            </div>
          )}
          {!open && (
            <img
              src="/assets/images/logo-icon.png"
              alt="logo"
              className="w-10"
            />
          )}
        </div>
        <div className={`flex-auto ${open ? 'px-2' : 'px-0'} mb-[1px]`}>
          {props.routeGroups
            .filter(
              group =>
                group.mainRoute.main &&
                group.mainRoute.drawable &&
                userHasScope(group.mainRoute.scope ?? ''),
            )
            .map((group, i) => (
              <div key={i} style={{ position: 'relative' }}>
                <Link
                  className="block"
                  href={{
                    pathname: group.mainRoute.path,
                    query: { ...previousQueryParams },
                  }}
                >
                  {open ? (
                    <MenuItem
                      className={`${
                        group.routes.find(
                          route => route.path == router.pathname,
                        ) || group.mainRoute.path == router.pathname
                          ? 'text-cyan-700 bg-cyan-50'
                          : ''
                      } rounded`}
                    >
                      {group.mainRoute.icon}
                      <span className="ml-1.5 inline-block leading-none">
                        {group.mainRoute.name}
                      </span>
                    </MenuItem>
                  ) : (
                    <Tooltip
                      title={group.mainRoute.name}
                      variant="solid"
                      placement="right"
                    >
                      <MenuItem
                        className={`${
                          group.routes.find(
                            route => route.path == router.pathname,
                          ) || group.mainRoute.path == router.pathname
                            ? 'text-cyan-700 bg-cyan-50'
                            : ''
                        } rounded-none py-2 justify-center`}
                      >
                        {group.mainRoute.icon}
                      </MenuItem>
                    </Tooltip>
                  )}
                </Link>

                {group.mainRoute.isMailerCheck &&
                  (open ? (
                    <div className="md:absolute top-0 right-0 ">
                      <EmailStatusDialog />
                    </div>
                  ) : (
                    <Tooltip
                      title="Email Status"
                      variant="solid"
                      placement="right"
                    >
                      <div className="flex-none w-full">
                        <div>
                          <EmailStatusDialog />
                        </div>
                      </div>
                    </Tooltip>
                  ))}
              </div>
            ))}
        </div>
        <div className="flex-none w-full">
          <div className={open ? 'p-4' : 'p-0'}>
            {profileMenuOpen && (
              <div>
                {props.routeGroups
                  .filter(
                    group =>
                      !group.mainRoute.main &&
                      group.mainRoute.drawable &&
                      userHasScope(group.mainRoute.scope ?? ''),
                  )
                  .map((group, i) => (
                    <Link
                      className="block"
                      key={i}
                      href={{
                        pathname: group.mainRoute.path,
                        query: { ...previousQueryParams },
                      }}
                    >
                      {open ? (
                        <MenuItem
                          className={`${
                            group.routes.find(r => r.path == router.pathname)
                              ? 'text-cyan-700 bg-slate-100'
                              : ''
                          } rounded`}
                        >
                          {group.mainRoute.icon}
                          <span className="ml-1.5 inline-block leading-none">
                            {group.mainRoute.name}
                          </span>
                        </MenuItem>
                      ) : (
                        <Tooltip
                          title={group.mainRoute.name}
                          variant="solid"
                          placement="right"
                        >
                          <MenuItem
                            className={`${
                              group.routes.find(r => r.path == router.pathname)
                                ? 'text-cyan-700 bg-slate-100'
                                : ''
                            } rounded-none py-2 justify-center`}
                          >
                            {group.mainRoute.icon}
                          </MenuItem>
                        </Tooltip>
                      )}
                    </Link>
                  ))}
                <MenuItem
                  className={`rounded ${!open ? 'justify-center mb-2' : ''}`}
                  onClick={() => handleLogout()}
                >
                  <LogOut01 />
                  {open && (
                    <span className="ml-1.5 inline-block leading-none">
                      Logout
                    </span>
                  )}
                </MenuItem>
              </div>
            )}
          </div>
          <div
            className={`flex items-center border-t border-slate-200 ${
              open
                ? 'py-4 pl-4 pr-2 flex-row'
                : 'p-0 py-2 justify-center flex-col-reverse'
            } cursor-pointer hover:bg-slate-50 relative`}
            onClick={() => setProfileMenuOpen(!profileMenuOpen)}
          >
            <div className="w-10 h-10 rounded-full flex items-center justify-center bg-cyan-600 flex-none">
              <p className="text-white">{profileInitials()}</p>
            </div>
            {open && (
              <div className="ml-2 grid flex-auto">
                <p className="text-sm font-medium text-slate-700">
                  {userProfile?.firstName ?? 'User'}{' '}
                  {userProfile?.lastName ?? 'Name'}
                </p>
                <span className="text-xs text-slate-500 capitalize">
                  Role:{' '}
                  <span className="text-teal-700">
                    {userProfile?.roles[0] ?? 'Staff'}
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
      </SidebarParent>
      <div
        className={`h-full flex flex-col transition-all ${open ? 'ml-64' : 'ml-12'}`}
      >
        <UIElements />

        {props.mainContent}
      </div>
    </>
  );
};

export default Layout;
