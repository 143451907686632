import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { NagProps } from '../../components/nag/nag';
import createEntityHooks from '../utils/create-entity-hooks';

interface UIState {
  nags: Record<string, NagProps | undefined>;
}

const initialState: UIState = { nags: {} };

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setNag: (
      state,
      action: PayloadAction<{
        id: keyof UIState['nags'];
        nag: NagProps | undefined;
      }>,
    ) => {
      const { id, nag } = action.payload;
      state.nags[id] = nag;
    },
  },
});

export const { setNag } = uiSlice.actions;

const [useNags, useMutateNag] = createEntityHooks(uiSlice, 'nags', setNag);

export { useMutateNag, useNags };

export default uiSlice.reducer;
