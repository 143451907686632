/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

export type DayOfMonthSchedule = Static<typeof DayOfMonthSchedule>;
export const DayOfMonthSchedule = Type.Object(
  {
    minutes: Type.Array(Type.Number(), {
      minItems: 1,
      maxItems: 1,
    }),
    hours: Type.Array(Type.Number(), {
      minItems: 1,
      maxItems: 1,
    }),
    month: Type.Object(
      {
        start: Type.Literal('*'),
        increment: Type.Number({ minimum: 1, maximum: 12 }),
      },
      {
        additionalProperties: false,
        required: ['start', 'increment'],
      },
    ),
    dayOfMonth: Type.Array(Type.Number({ minimum: 1, maximum: 31 }), {
      minItems: 1,
      maxItems: 1,
    }),
    dayOfWeek: Type.Optional(
      Type.Array(Type.Never(), { default: [], maxItems: 0 }),
    ),
    year: Type.Optional(Type.Array(Type.Never(), { default: [], maxItems: 0 })),
    nearestWeekday: Type.Optional(Type.Literal(false, { default: false })),
    lastDayOfMonth: Type.Optional(Type.Literal(false, { default: false })),
    lastInstanceOfDayOfWeek: Type.Optional(
      Type.Literal(false, { default: false }),
    ),
  },
  {
    additionalProperties: false,
    required: ['dayOfMonth', 'hours', 'minutes', 'month'],
  },
);
