interface BuildHeadersInterface {
  accessToken?: string;
  contentType?: string;
}

// Build headers for request
export const buildHeaders = ({
  accessToken,
  contentType = 'application/json',
}: BuildHeadersInterface): Headers => {
  const headers = new Headers();
  headers.append('Content-Type', contentType);

  if (accessToken) {
    headers.append('Authorization', `Bearer ${accessToken}`);
  }

  return headers;
};

interface BuildURLInterface {
  path: string;
  query?: Record<string, string | undefined>;
}

export const buildURL = ({ path, query }: BuildURLInterface): string => {
  const queryParams = new URLSearchParams();

  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      if (value) {
        queryParams.append(key, value);
      }
    });
  }

  const stringifiedQueryParams = queryParams.toString();

  return `${path}${stringifiedQueryParams ? `?${stringifiedQueryParams}` : ''}`;
};
