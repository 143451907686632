import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify } from 'aws-amplify';
import {
  AuthSession,
  confirmResetPassword,
  confirmSignIn,
  ConfirmSignInOutput,
  confirmUserAttribute,
  fetchAuthSession,
  getCurrentUser,
  GetCurrentUserOutput,
  resetPassword,
  sendUserAttributeVerificationCode,
  signIn,
  SignInOutput,
  signOut,
  updateMFAPreference,
  updatePassword,
  updateUserAttributes,
} from 'aws-amplify/auth';

import appConfig from '../../config/app.config';
import { cleanPhone } from '../helpers/custom-validators';
import { User } from '../store/auth/types';
import { cognitoErrorCodes } from './errors';
import logger from './logger';

const getErrorCode = (err: unknown): keyof typeof cognitoErrorCodes => {
  const error = err as Record<string, string>;
  return error.code || error.name;
};

const handleError = (err: unknown): Error => {
  logger.error(err, 'CognitoService:handleError');
  const defaultMessage = 'Unknown Authentication Error';
  const code = getErrorCode(err);
  if (code) {
    const message = cognitoErrorCodes[code] || defaultMessage;
    return new Error(message);
  } else {
    return new Error(defaultMessage);
  }
};

export interface Auth {
  role?: string;
}

export interface TokenMetadata {
  accessToken?: string;
  idToken?: string;
  accessTokenExpiration?: number;
  idTokenExpiration?: number;
}

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: appConfig.cognitoUserPoolId,
      userPoolClientId: appConfig.cognitoClientId,
    },
  },
});

class CognitoService {
  cognitoUser: CognitoUser | null;
  cognitoUserSession: CognitoUserSession | null;
  private newPasswordAttributes: Record<string, string> | null;

  constructor() {
    this.cognitoUser = null;
    this.cognitoUserSession = null;
    this.newPasswordAttributes = null;
  }

  private extractTokens = (session?: AuthSession): TokenMetadata => {
    if (!session || !session.tokens) {
      return {};
    }

    const { accessToken: accessTokenObj, idToken: idTokenObj } = session.tokens;

    const accessToken = accessTokenObj.toString();
    const idToken = idTokenObj?.toString();
    const accessTokenExpiration = accessTokenObj.payload.exp;
    const idTokenExpiration = idTokenObj?.payload.exp;
    // WE CAN USE THIS TO GET USER ATTRIBUTES IN THE FUTURE
    // const customData = session.getIdToken().decodePayload();
    return {
      accessToken,
      idToken,
      accessTokenExpiration: accessTokenExpiration
        ? accessTokenExpiration * 1000
        : undefined,
      idTokenExpiration: idTokenExpiration
        ? idTokenExpiration * 1000
        : undefined,
    };
  };

  enableMfa = async () => {
    try {
      await updateMFAPreference({
        sms: 'PREFERRED',
      });
    } catch (error) {
      throw handleError(error as Error);
    }
  };

  changeMfaStatus = async (enable: boolean) => {
    try {
      await updateMFAPreference({
        sms: enable ? 'PREFERRED' : 'DISABLED',
      });
    } catch (error) {
      throw handleError(error as Error);
    }
  };

  changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      await updatePassword({
        oldPassword,
        newPassword,
      });

      return true;
    } catch (error) {
      throw handleError(error as Error);
    }
  };

  setAttribute = async (value: string, attribute: string) => {
    try {
      await updateUserAttributes({
        userAttributes: {
          [attribute]: attribute === 'phone_number' ? cleanPhone(value) : value,
        },
      });
    } catch (error) {
      throw handleError(error as Error);
    }
  };

  requestAttributeVerification = async (
    attribute: 'email' | 'phone_number',
  ) => {
    try {
      await sendUserAttributeVerificationCode({
        userAttributeKey: attribute,
        [attribute]: attribute,
      });
      return true;
    } catch (error) {
      throw handleError(error as Error);
    }
  };

  requestVerification = async (
    attribute?: 'email' | 'phone_number',
    attributeValue?: string,
    isNew?: boolean,
  ) => {
    try {
      if (!attribute || !attributeValue) {
        throw new Error('Attribute and attribute value are required');
      }
      if (isNew) {
        return await this.setAttribute(attributeValue, attribute);
      }
      return await this.requestAttributeVerification(attribute);
    } catch (error) {
      throw handleError(error as Error);
    }
  };

  verifyAttribute = async (
    code: string,
    attribute?: 'email' | 'phone_number',
  ) => {
    if (!attribute) {
      throw new Error('Attribute is required');
    }
    try {
      await confirmUserAttribute({
        confirmationCode: code,
        userAttributeKey: attribute,
      });
      return true;
    } catch (error) {
      throw handleError(error as Error);
    }
  };

  sendMFACode = async (mfacode: string) => {
    try {
      await confirmSignIn({
        challengeResponse: mfacode,
      });
    } catch (error) {
      throw handleError(error as Error);
    }
  };

  completeNewPasswordChallenge = async (newPassword: string) => {
    try {
      await confirmSignIn({
        challengeResponse: newPassword,
      });
    } catch (error) {
      throw handleError(error as Error);
    }
  };

  forgotPassword = async (email: string) => {
    try {
      await resetPassword({
        username: email,
      });

      return { email } as User;
    } catch (error) {
      throw handleError(error as Error);
    }
  };

  confirmPassword = async (email: string, code: string, password: string) => {
    try {
      await confirmResetPassword({
        confirmationCode: code,
        newPassword: password,
        username: email,
      });
    } catch (error) {
      throw handleError(error as Error);
    }
  };

  auth = async (email: string, password: string, mfaCode?: string) => {
    try {
      let result: ConfirmSignInOutput | SignInOutput | undefined = undefined;

      if (mfaCode) {
        result = await confirmSignIn({
          challengeResponse: mfaCode,
        });
      } else {
        result = await signIn({
          username: email,
          password,
        });
      }

      let user: GetCurrentUserOutput | undefined = undefined;
      if (result.isSignedIn) {
        user = await getCurrentUser();
      }

      const currentSession = await fetchAuthSession();
      const tokens = this.extractTokens(currentSession);

      return {
        user,
        ...tokens,
        ...result,
      };
    } catch (error) {
      throw handleError(error as Error);
    }
  };

  refreshSession = async () => {
    const session = await fetchAuthSession({ forceRefresh: true });

    const user = await getCurrentUser();
    return {
      user,
      ...this.extractTokens(session),
    };
  };

  logout = async () => {
    try {
      await signOut();
    } catch (error) {
      logger.error(error, 'CognitoService:logout');
      throw new Error('Logout failed');
    }
  };
}

const cognitoService = new CognitoService();

export default cognitoService;
