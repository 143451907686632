import Button from '@mui/joy/Button';
import React from 'react';

export interface NagProps {
  cta?: string;
  onClick?: () => void;
  title?: string;
  type?: 'info' | 'neutral' | 'danger' | 'warning' | 'success';
  children?: React.ReactNode;
}

const Nag = ({ children, cta, onClick, title, type = 'info' }: NagProps) => {
  const getStyles = () => {
    switch (type) {
      case 'info':
        return 'bg-cyan-100 text-cyan-900 border-cyan-200';
      case 'neutral':
        return 'bg-slate-100 text-slate-900 border-slate-200';
      case 'danger':
        return 'bg-red-100 text-red-900 border-red-200';
      case 'warning':
        return 'bg-orange-100 text-orange-900 border-orange-200';
      default:
        return 'bg-green-100 text-green-900 border-green-200';
    }
  };

  const getButtonType = () => {
    switch (type) {
      case 'info':
        return 'primary';
      default:
        return type;
    }
  };

  return (
    <div className={`p-4 gap-4 border-b ${getStyles()}`}>
      <div className="flex items-center">
        <div className="flex-auto">
          {!children && <p className="text-base font-medium">{title}</p>}
          {children}
        </div>
        {cta && (
          <div className="flex-none">
            <Button size="sm" color={getButtonType()} onClick={onClick}>
              {cta}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Nag;
