import { CompanyPayload } from '@rego-engage/engage-types';

import { engageApi } from '../engage.api';
import { buildURL } from './../../../helpers/build-headers';
import type {
  CreateCompanyQuery,
  DeleteCompanyQuery,
  GetCompanyQuery,
  UpdateCompanyQuery,
} from './company.interface';

const companyPath = '/company';

export const companyApi = engageApi
  .enhanceEndpoints({
    addTagTypes: ['Company', 'Companies'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: build => ({
      createCompany: build.mutation<CompanyPayload, CreateCompanyQuery>({
        query: ({ body }) => ({
          url: buildURL({ path: companyPath }),
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Companies'],
      }),
      listCompanies: build.query<CompanyPayload[], void>({
        query: () => ({
          url: buildURL({ path: companyPath }),
          method: 'GET',
        }),
        providesTags: ['Companies'],
      }),
      getCompany: build.query<CompanyPayload, GetCompanyQuery>({
        query: ({ companyId }) => ({
          url: buildURL({ path: `${companyPath}/${companyId}` }),
          method: 'GET',
        }),
        providesTags: ['Company'],
      }),
      updateCompany: build.mutation<CompanyPayload, UpdateCompanyQuery>({
        query: ({ companyId, body }) => ({
          url: buildURL({ path: `${companyPath}/${companyId}` }),
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['Company', 'Companies'],
      }),
      deleteCompany: build.mutation<CompanyPayload, DeleteCompanyQuery>({
        query: ({ companyId }) => ({
          url: buildURL({ path: `${companyPath}/${companyId}` }),
          method: 'DELETE',
        }),
        invalidatesTags: ['Companies'],
      }),
    }),
  });

export const useCreateCompanyMutation = companyApi.useCreateCompanyMutation;
export const useListCompaniesQuery = companyApi.useListCompaniesQuery;
export const useGetCompanyQuery = companyApi.useGetCompanyQuery;
export const useUpdateCompanyMutation = companyApi.useUpdateCompanyMutation;
export const useDeleteCompanyMutation = companyApi.useDeleteCompanyMutation;
