/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

import { JobSchema } from './job.schema';

export type JobPayload = Static<typeof JobPayload>;
export const JobPayload = Type.Pick(
  JobSchema,
  Type.Union([
    Type.Literal('taskId'),
    Type.Literal('name'),
    Type.Literal('runFrom'),
    Type.Literal('runUntil'),
    Type.Literal('timezone'),
    Type.Literal('inputParams'),
    Type.Literal('scheduleParams'),
  ]),
);
