/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

export type DailySchedule = Static<typeof DailySchedule>;
export const DailySchedule = Type.Object(
  {
    minutes: Type.Array(Type.Number(), {
      default: [],
      minItems: 1,
      maxItems: 1,
    }),
    hours: Type.Array(Type.Number(), { default: [], minItems: 1, maxItems: 1 }),
    dayOfMonth: Type.Object(
      {
        start: Type.Literal('*'),
        increment: Type.Number({ minimum: 1, maximum: 31 }),
      },
      {
        additionalProperties: false,
        required: ['start', 'increment'],
      },
    ),
    dayOfWeek: Type.Optional(
      Type.Array(Type.Never(), { default: [], maxItems: 0 }),
    ),
    month: Type.Optional(
      Type.Array(Type.Never(), { default: [], maxItems: 0 }),
    ),
    year: Type.Optional(Type.Array(Type.Never(), { default: [], maxItems: 0 })),
    // dayOfWeekInstance: Type.Optional(Type.Number()),
    nearestWeekday: Type.Optional(Type.Literal(false, { default: false })),
    lastDayOfMonth: Type.Optional(Type.Literal(false, { default: false })),
    lastInstanceOfDayOfWeek: Type.Optional(
      Type.Literal(false, { default: false }),
    ),
  },
  {
    additionalProperties: false,
    required: ['minutes', 'hours', 'dayOfMonth'],
  },
);
