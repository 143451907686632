/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

export type DayOfWeekInstanceSchedule = Static<
  typeof DayOfWeekInstanceSchedule
>;
export const DayOfWeekInstanceSchedule = Type.Object(
  {
    minutes: Type.Array(Type.Number(), {
      minItems: 1,
      maxItems: 1,
    }),
    hours: Type.Array(Type.Number(), {
      minItems: 1,
      maxItems: 1,
    }),
    month: Type.Object(
      {
        start: Type.Literal('*'),
        increment: Type.Number({ minimum: 1, maximum: 12 }),
      },
      {
        additionalProperties: false,
        required: ['start', 'increment'],
      },
    ),
    dayOfWeekInstance: Type.Optional(
      Type.Union([
        Type.Literal(1),
        Type.Literal(2),
        Type.Literal(3),
        Type.Literal(4),
        Type.Literal(5),
      ]),
    ),
    lastInstanceOfDayOfWeek: Type.Boolean(),
    dayOfWeek: Type.Array(
      Type.Union([
        Type.Literal(1),
        Type.Literal(2),
        Type.Literal(3),
        Type.Literal(4),
        Type.Literal(5),
        Type.Literal(6),
        Type.Literal(7),
      ]),
      {
        minItems: 1,
        maxItems: 1,
      },
    ),
    dayOfMonth: Type.Optional(Type.Array(Type.Never(), { maxItems: 0 })),
    year: Type.Optional(Type.Array(Type.Never(), { default: [], maxItems: 0 })),
    nearestWeekday: Type.Optional(Type.Literal(false, { default: false })),
    lastDayOfMonth: Type.Optional(Type.Literal(false, { default: false })),
  },
  {
    additionalProperties: false,
    required: [
      'dayOfWeekInstance',
      'lastInstanceDayOfWeek',
      'dayOfWeek',
      'hours',
      'minutes',
      'month',
    ],
  },
);
