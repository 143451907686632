/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

export type DataExtractor = Static<typeof DataExtractor>;
export const DataExtractor = Type.Object({
  dataExtractorId: Type.String(),
  companyId: Type.String(),
  userId: Type.String(),
  templateId: Type.String(),
  sourceType: Type.Union([
    Type.Literal('JSON_REST_API'),
    Type.Literal('STAGING'),
  ]),
  code: Type.String(),
  name: Type.String(),
  executionOrder: Type.Number(),
  isHidden: Type.Optional(Type.Boolean()),
  preStageScripts: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  postStageScripts: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  defaultOrder: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  defaultOrderDirection: Type.Optional(
    Type.Union([Type.Literal('ASC'), Type.Literal('DESC'), Type.Null()]),
  ),
  remotePath: Type.Union([Type.String(), Type.Null()]),
  jsonStartingPath: Type.Union([Type.String(), Type.Null()]),
  previousExtractorsRequired: Type.Optional(
    Type.Union([Type.String(), Type.Null()]),
  ),
  sqlQuery: Type.Union([Type.String(), Type.Null()]),
});
