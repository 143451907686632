import Modal from '@mui/joy/Modal';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useEffect, useState } from 'react';

import useSessionManagement from './use-session-management';

interface SessionExpirationModalProps {
  /**
   * Time in seconds before session expiration to start countdown
   * @default 60 seconds
   */
  countdownStart?: number;

  /**
   * Maximum idle time in milliseconds.
   * @default 15 minutes
   */
  maxIdleTime?: number;

  /**
   * Time in milliseconds to wait before logging out the user
   * @default 1 minute
   */
  idleTimeout?: number;

  /**
   * Callback to call when the time expires
   */
  onTimeExpired: () => void;
}

const DEFAULT_COUNTDOWN_START = 60; // 60 seconds
const DEFAULT_MAX_IDLE_TIME = 1000 * 60 * 15; // 15 minutes

const SessionExpirationModal = ({
  countdownStart = DEFAULT_COUNTDOWN_START,
  maxIdleTime = DEFAULT_MAX_IDLE_TIME,
  idleTimeout,
  onTimeExpired,
}: SessionExpirationModalProps) => {
  const { idleSince, isUserActive } = useSessionManagement(idleTimeout);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(countdownStart);

  // Close the modal if the user becomes active
  useEffect(() => {
    if (isUserActive) {
      setIsModalOpen(false);
      setSecondsRemaining(countdownStart);
    }
  }, [countdownStart, isUserActive]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (idleSince) {
        const timeElapsedSinceIdle = Date.now() - idleSince;
        const timeLeft = maxIdleTime - timeElapsedSinceIdle;

        if (timeLeft <= countdownStart * 1000) {
          setIsModalOpen(true);
          setSecondsRemaining(Math.ceil(timeLeft / 1000));
        } else {
          setIsModalOpen(false);
        }

        if (timeLeft <= 0) {
          clearInterval(intervalId);
          onTimeExpired();
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [idleSince, countdownStart, onTimeExpired, maxIdleTime]);

  return (
    <Modal
      open={isModalOpen}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Sheet
        sx={{
          p: 2,
          textAlign: 'center',
          maxWidth: '400px',
          mx: 'auto',
          my: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack spacing={2}>
          <Typography level="h4">Session Expiring Soon</Typography>
          <Typography>
            You will be logged out in {secondsRemaining} seconds.
          </Typography>
        </Stack>
      </Sheet>
    </Modal>
  );
};

export default SessionExpirationModal;
