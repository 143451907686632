/* eslint-disable @typescript-eslint/no-redeclare */
export type JobStatus = (typeof JobStatus)[keyof typeof JobStatus];

export const JobStatus = {
  NOT_RUNNING: 'NOT_RUNNING',
  RUNNING: 'RUNNING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  TIMED_OUT: 'TIMED_OUT',
  ABORTED: 'ABORTED',
} as const;
