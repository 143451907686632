import { ActionCreatorWithPayload, Slice } from '@reduxjs/toolkit'; // Import the Slice type
import { useDispatch, useSelector } from 'react-redux';

function createEntityHooks<S, K extends keyof S, P>(
  slice: Slice<S>,
  entityName: K,
  actionCreator: ActionCreatorWithPayload<P>,
): [() => S[K], () => (payload: P) => void];
function createEntityHooks<S, K extends keyof S>(
  slice: Slice<S>,
  entityName: K,
): [() => S[K]];
function createEntityHooks<S, K extends keyof S, P>(
  slice: Slice<S>,
  entityName: K,
  actionCreator?: ActionCreatorWithPayload<P>,
): [() => S[K], (() => (payload: P) => void)?] {
  // Create a selector for the entity
  const selector = (state: Record<string, S>) => state[slice.name][entityName];
  const useGetEntity = () => useSelector(selector);

  const useMutateEntity = actionCreator
    ? () => {
        const dispatch = useDispatch();
        return (payload: P) => dispatch(actionCreator(payload));
      }
    : undefined;

  return [useGetEntity, useMutateEntity];
}

export default createEntityHooks;
