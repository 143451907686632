import { extendTheme } from '@mui/joy/styles';
import type {
  DefaultPaletteRange,
  PaletteVariant,
} from '@mui/joy/styles/types';
import { alpha } from '@mui/material';

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: '#ecfeff',
          100: '#cffafe',
          200: '#99f6e4',
          300: '#67e8f9',
          400: '#22d3ee',
          500: '#0891b2',
          600: '#0e7490',
          700: '#0e7490',
          800: '#155e75',
          900: '#164e63',
        },
        neutral: {
          50: '#f8fafc',
          100: '#f1f5f9',
          200: '#e2e8f0',
          300: '#cbd5e1',
          400: '#94a3b8',
          500: '#64748b',
          600: '#475569',
          700: '#334155',
          800: '#1e293b',
          900: '#0f172a',
        },
        slate: {
          800: 'rgb(30 41 59 / 1)',
        },
        calendar: {
          500: alpha('#5e35b1', 0.4),
          yearly: '#b13599',
          monthly: '#b13a35',
          weekly: '#b1a435',
          daily: '#52b135',
          hourly: '#35b181',
          minutely: '#3576b1',
          secondly: '#5e35b1',
        },
      },
    },
  },
  components: {
    JoyFormControl: {
      styleOverrides: {
        root: {
          //   '--FormHelperText-margin': '0.1875rem 0 0.375rem 0',
          //   '--FormHelperText-lineHeight': 'var(--joy-lineHeight-xl)', // 1.66667
          '--FormHelperText-fontSize': 'var(--joy-fontSize-xs)', // 0.75rem
        },
      },
    },
    JoyFormHelperText: {
      styleOverrides: {
        root: {
          minHeight: 'calc(var(--FormHelperText-lineHeight) * 1em)', // 20px
          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          letterSpacing: '0.03333em',
        },
      },
    },
    JoySelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          // Fix for Joy not setting placeholder opacity when multiple is true
          // and value is an empty array. Null is not valid for multiple mode.
          ...(ownerState.multiple === true &&
            Array.isArray(ownerState.value) &&
            ownerState.value.length === 0 && {
              opacity: 'var(--Select-placeholderOpacity)',
            }),
        }),
      },
    },
    JoyDialogTitle: {
      defaultProps: {
        level: 'title-md',
      },
      styleOverrides: {
        root: {
          color: 'var(--joy-palette-slate-800)',
          borderBottom: '1px solid var(--joy-palette-divider)',
          paddingBottom: '0.75em',
          paddingLeft: '1em',
          paddingRight: '1em',
        },
      },
    },
    JoyDialogActions: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          padding: 'calc(0.625* var(--Card-padding)) 0 0 0',
          paddingLeft: '1em',
        },
      },
    },
  },
});

interface CalendarColors {
  yearly?: string;
  monthly?: string;
  weekly?: string;
  daily?: string;
  hourly?: string;
  minutely?: string;
  secondly?: string;
}

declare module '@mui/joy/styles' {
  interface Palette {
    slate: DefaultPaletteRange;
    calendar: DefaultPaletteRange & PaletteVariant & CalendarColors;
  }
}
