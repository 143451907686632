import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useGetUserProfileQuery } from '../services/engage/user-profile/user-profile.api';
import logger from '../services/logger';
import { logout } from '../store/auth/actions';
import { useAuth } from '../store/auth/auth.slice';
import { useAppDispatch } from '../store/store';

interface ProfileProviderProps {
  loader?: React.ReactNode;
  children?: React.ReactNode;
}

export const ProfileProvider = ({ loader, children }: ProfileProviderProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const { isAuth, loading: authLoading } = useAuth();

  const {
    data: userProfile,
    isLoading: userProfileLoading,
    isError: userProfileError,
  } = useGetUserProfileQuery(undefined, {
    skip: !isAuth || authLoading,
  });

  useEffect(() => {
    if (userProfileError) {
      logger.error('Error fetching user profile');
      dispatch(logout(null))
        .then(() =>
          router.push('/').catch((err: unknown) => logger.error({ err })),
        )
        .catch((err: unknown) => logger.error({ err }, 'Error logging out'));
    }
  }, [userProfileError, router, dispatch]);

  if (loader && (authLoading || userProfileLoading)) {
    return loader;
  }

  if (isAuth && !userProfile && !userProfileLoading) {
    return <div>No profile data</div>;
  }

  return children;
};

export default ProfileProvider;
