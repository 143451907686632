export const LOCALSTORAGE_EMAIL_PROGRESS_KEY = 'emailprogress';
export const LOCALSTORAGE_STATUS_BAR_KEY = 'statusBar';
export interface EmailProgress {
  id: string;
  title: string;
  details: string;
  progress: number;
  status:
    | 'NOT RUNNING'
    | 'RUNNING'
    | 'SUCCEEDED'
    | 'FAILED'
    | 'TIMED OUT'
    | 'ABORTED';
  filters?: Record<string, unknown>;
  createdAt: number;
}

export interface EmailTemplateInfo {
  templateId: string;
  name: string;
  filters: Record<string, unknown>;
}
