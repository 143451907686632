/**
 * Interface for parameters for backoff time calculation.
 */
export interface ExponentialBackoffParams {
  /**
   * The current attempt number.
   */
  attempt: number;
  /**
   * The maximum number of retries allowed.
   * @default 5
   */
  maxRetries?: number;
  /**
   * The randomization factor for jitter calculation.
   * @default 0.4
   */
  randomizationFactor?: number;
  /**
   * The initial delay in milliseconds.
   * @default 300
   */
  initialDelay?: number;
  /**
   * The backoff factor for exponential delay calculation.
   * @default 2
   */
  backoffFactor?: number;
  /**
   * The maximum delay in milliseconds.
   * @default 10000
   */
  maxDelay?: number;
  /**
   * A flag to choose between two jitter calculation variants (false = Symmetric Jitter, true = Asymmetric Jitter).
   * @default false
   */
  jitterVariant?: boolean;
}

/**
 * Calculates backoff time with exponential delay and jitter.
 *
 * The function implements two jitter variants:
 * - Symmetric Jitter (default): The delay time can be both increased and decreased by a random factor.
 *   This provides a balanced, bidirectional variation around the computed delay.
 * - Asymmetric Jitter: When the 'jitterVariant' flag is true, the delay time can only be increased by a random factor, never decreased.
 *   This provides an upward-biased variation, potentially leading to longer, but more predictable and steady, delays.
 *
 * @export
 * @param {ExponentialBackoffParams} params - An object containing parameters for backoff time calculation.
 * @returns The calculated backoff time in milliseconds.
 */
const exponentialBackoff = ({
  attempt,
  maxRetries = 5,
  randomizationFactor = 0.4,
  initialDelay = 300,
  backoffFactor = 2,
  maxDelay = 10000,
  jitterVariant = false,
}: ExponentialBackoffParams): number => {
  // Limit the number of attempts to maxRetries
  const attempts = Math.min(attempt, maxRetries);

  // Calculate random component based on jitterVariant flag
  // - Symmetric Jitter (default): randomization factor can both increase and decrease the delay
  // - Asymmetric Jitter: randomization factor only increases the delay
  const randomComponent = jitterVariant
    ? Math.random() * randomizationFactor
    : Math.random() + randomizationFactor;

  // Calculate the delay considering the random component and exponential backoff
  const delay = Math.floor(
    initialDelay * randomComponent * backoffFactor ** attempts,
  );

  // Ensure the calculated delay does not exceed the specified maximum delay
  return Math.min(delay, maxDelay);
};

export default exponentialBackoff;
