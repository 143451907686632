import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import createEntityHooks from '../utils/create-entity-hooks';

interface SessionState {
  idleSince: number | null;
  isUserActive: boolean;
  isTabActive: boolean;
}

const initialState: SessionState = {
  idleSince: null,
  isUserActive: true,
  isTabActive: true,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setUserActive: (state, action: PayloadAction<boolean>) => {
      state.idleSince = action.payload ? null : Date.now();
      state.isUserActive = action.payload;
    },
    setTabFocused: (state, action: PayloadAction<boolean>) => {
      state.isTabActive = action.payload;
    },
  },
});

export const { setUserActive, setTabFocused } = sessionSlice.actions;

const [useIdleSince] = createEntityHooks(sessionSlice, 'idleSince');

const [useIsUserActive, useMutateIsUserActive] = createEntityHooks(
  sessionSlice,
  'isUserActive',
  setUserActive,
);

const [useIsTabActive, useMutateIsTabActive] = createEntityHooks(
  sessionSlice,
  'isTabActive',
  setTabFocused,
);

export {
  useIdleSince,
  useIsTabActive,
  useIsUserActive,
  useMutateIsTabActive,
  useMutateIsUserActive,
};
