import Nag from '../../components/nag/nag';
import { useNags } from './ui.slice';

interface UIElementsProps {
  children?: React.ReactNode;
}

export const UIElements = ({ children }: UIElementsProps) => {
  const nags = useNags();
  return (
    <>
      {nags &&
        Object.entries(nags).map(([key, v]) => v && <Nag key={key} {...v} />)}
      {children}
    </>
  );
};
