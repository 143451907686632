/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

export type DaysOfWeekSchedule = Static<typeof DaysOfWeekSchedule>;
export const DaysOfWeekSchedule = Type.Object(
  {
    minutes: Type.Array(Type.Number(), {
      minItems: 1,
      maxItems: 1,
    }),
    hours: Type.Array(Type.Number(), {
      minItems: 1,
      maxItems: 1,
    }),
    dayOfWeek: Type.Array(
      Type.Union([
        Type.Literal(1),
        Type.Literal(2),
        Type.Literal(3),
        Type.Literal(4),
        Type.Literal(5),
        Type.Literal(6),
        Type.Literal(7),
      ]),
      {
        minItems: 1,
        maxItems: 7,
      },
    ),
    dayOfMonth: Type.Optional(Type.Array(Type.Never(), { maxItems: 0 })),
    month: Type.Optional(Type.Array(Type.Never(), { maxItems: 0 })),
    year: Type.Optional(Type.Array(Type.Never(), { maxItems: 0 })),
    nearestWeekday: Type.Optional(Type.Literal(false)),
    lastDayOfMonth: Type.Optional(Type.Literal(false)),
    lastInstanceOfDayOfWeek: Type.Optional(Type.Literal(false)),
  },
  {
    additionalProperties: false,
    required: ['hours', 'minutes', 'dayOfWeek'],
  },
);
