export const cognitoErrorCodes: Record<string, string> = {
  InternalErrorException: 'Internal error',
  InvalidParameterException: 'Invalid parameter',
  NotAuthorizedException: 'Not authorized, please check your credentials',
  ResourceNotFoundException: 'Resource not found',
  TooManyRequestsException: 'Too many requests, try again later',
  UserImportInProgressException: 'User import in progress',
  UserNotFoundException: 'User not found',
  InvalidLambdaResponseException: 'Invalid lambda response',
  LimitExceededException: 'Limit exceeded',
  TooManyFailedAttemptsException: 'Too many failed attempts, try again later',
  UnexpectedLambdaException: 'Unexpected lambda',
  UserLambdaValidationException: 'User lambda validation',
  CodeDeliveryFailureException: 'Code delivery failure',
  InvalidPasswordException: 'Invalid password',
  InvalidSmsRoleAccessPolicyException: 'Invalid SMS role access policy',
  InvalidSmsRoleTrustRelationshipException:
    'Invalid SMS role trust relationship',
  PreconditionNotMetException: 'Precondition not met',
  UnsupportedUserStateException: 'Unsupported user state',
  UsernameExistsException: 'Username exists',
  AliasExistsException: 'Alias exists',
  InvalidUserPoolConfigurationException: 'Invalid user pool configuration',
  MFAMethodNotFoundException: 'MFA method not found',
  PasswordResetRequiredException: 'Password reset required',
  UserNotConfirmedException: 'User not confirmed',
  UserPoolAddOnNotEnabledException: 'User pool add-on not enabled',
  InvalidEmailRoleAccessPolicyException: 'Invalid email role access policy',
  CodeMismatchException: 'Code mismatch, try again',
  ExpiredCodeException: 'Expired code',
  SoftwareTokenMFANotFoundException: 'Software token MFA not found',
  ConcurrentModificationException: 'Concurrent modification',
  ForbiddenException: 'Forbidden',
  GroupExistsException: 'Group exists',
  DuplicateProviderException: 'Duplicate provider',
  UserPoolTaggingException: 'User pool tagging',
  InvalidOAuthFlowException: 'Invalid OAuth flow',
  ScopeDoesNotExistException: 'Scope does not exist',
  UnsupportedIdentityProviderException: 'Unsupported identity provider',
  UnauthorizedException: 'Unauthorized',
  UnsupportedOperationException: 'Unsupported operation',
  UnsupportedTokenTypeException: 'Unsupported token type',
  EnableSoftwareTokenMFAException: 'Enable software token MFA',
};
