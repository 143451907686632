import {
  isRejectedWithValue,
  type Middleware,
  type MiddlewareAPI,
} from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import { useNotification } from '../../components/notification';

export const errorMiddleware: Middleware =
  (_api: MiddlewareAPI) => (next: Dispatch<unknown>) => (action: unknown) => {
    const notify = useNotification();
    if (isRejectedWithValue(action)) {
      const payload = action.payload as {
        data?: string | { statusCode: number; message: string };
      };
      if (payload.data && typeof payload.data === 'string') {
        notify({ message: payload.data, type: 'error' });
      } else if (payload.data && typeof payload.data === 'object') {
        notify({
          title: 'Failed',
          message: payload.data.message,
          type: 'error',
        });
      }
    }

    return next(action);
  };
