import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { RootState } from '../store';
import {
  authenticate,
  confirmPassword,
  forgotPasswordRequest,
  logout,
  refreshSession,
  signup,
} from './actions';
import { AuthState } from './types';

const initialState: AuthState = {
  isAuth: false,
  loading: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearError: (state: AuthState) => {
      state.error = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authenticate.pending, (state: AuthState) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(
        authenticate.fulfilled,
        (state: AuthState, action: PayloadAction<AuthState>) => {
          state.loading = action.payload.loading;
          state.isAuth = action.payload.isAuth;
          state.accessToken = action.payload.accessToken;
          state.idToken = action.payload.idToken;
          state.accessTokenExpiration = action.payload.accessTokenExpiration;
          state.idTokenExpiration = action.payload.idTokenExpiration;
          state.user = action.payload.user;
          state.isNewPasswordRequired = action.payload.isNewPasswordRequired;
          state.isPasswordResetRequired =
            action.payload.isPasswordResetRequired;
          state.isMFARequired = action.payload.isMFARequired;
          state.password = action.payload.password;
        },
      )
      .addCase(
        authenticate.rejected,
        (state, action: PayloadAction<unknown>) => {
          state.loading = false;
          if (action.payload && typeof action.payload === 'object') {
            const error = action.payload as AuthState;
            state.error = error.error;
          }
        },
      )
      .addCase(
        refreshSession.pending,
        (
          state: AuthState,
          {
            meta: {
              arg: { quietMode },
            },
          },
        ) => {
          state.loading = quietMode ? false : true;
          state.error = undefined;
        },
      )
      .addCase(
        refreshSession.fulfilled,
        (state: AuthState, action: PayloadAction<AuthState>) => {
          state.loading = action.payload.loading;
          state.isAuth = action.payload.isAuth;
          state.accessToken = action.payload.accessToken;
          state.idToken = action.payload.idToken;
          state.accessTokenExpiration = action.payload.accessTokenExpiration;
          state.idTokenExpiration = action.payload.idTokenExpiration;
          state.user = action.payload.user;
        },
      )
      .addCase(
        refreshSession.rejected,
        (state, action: PayloadAction<unknown>) => {
          state.loading = false;
          if (action.payload && typeof action.payload === 'object') {
            const error = action.payload as AuthState;
            state.error = error.error;
          }
        },
      )
      .addCase(logout.pending, (state: AuthState) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(logout.fulfilled, (state: AuthState) => {
        state.loading = false;
        state.isAuth = false;
        state.accessToken = undefined;
        state.idToken = undefined;
        state.user = undefined;
      })
      .addCase(logout.rejected, (state: AuthState) => {
        state.loading = false;
      })
      .addCase(signup.pending, (state: AuthState) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(
        signup.fulfilled,
        (state: AuthState, action: PayloadAction<AuthState>) => {
          state.loading = false;
          state.isAuth = true;
          state.accessToken = action.payload.accessToken;
          state.idToken = action.payload.idToken;
          state.accessTokenExpiration = action.payload.accessTokenExpiration;
          state.idTokenExpiration = action.payload.idTokenExpiration;
          state.user = action.payload.user;
        },
      )
      .addCase(signup.rejected, (state, action: PayloadAction<unknown>) => {
        state.loading = false;
        if (action.payload && typeof action.payload === 'object') {
          const error = action.payload as AuthState;
          state.error = error.error;
        }
      })
      .addCase(forgotPasswordRequest.pending, (state: AuthState) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(
        forgotPasswordRequest.fulfilled,
        (state: AuthState, action: PayloadAction<AuthState>) => {
          state.loading = false;
          state.isAuth = action.payload.isAuth;
          state.accessToken = action.payload.accessToken;
          state.idToken = action.payload.idToken;
          state.accessTokenExpiration = action.payload.accessTokenExpiration;
          state.idTokenExpiration = action.payload.idTokenExpiration;
          state.user = action.payload.user;
        },
      )
      .addCase(
        forgotPasswordRequest.rejected,
        (state, action: PayloadAction<unknown>) => {
          state.loading = false;
          if (action.payload && typeof action.payload === 'object') {
            const error = action.payload as AuthState;
            state.error = error.error;
          }
        },
      )
      .addCase(confirmPassword.pending, (state: AuthState) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(
        confirmPassword.fulfilled,
        (state: AuthState, action: PayloadAction<AuthState>) => {
          state.loading = action.payload.loading;
          state.isAuth = action.payload.isAuth;
          state.accessToken = action.payload.accessToken;
          state.idToken = action.payload.idToken;
          state.accessTokenExpiration = action.payload.accessTokenExpiration;
          state.idTokenExpiration = action.payload.idTokenExpiration;
          state.user = action.payload.user;
        },
      )
      .addCase(
        confirmPassword.rejected,
        (state, action: PayloadAction<unknown>) => {
          state.loading = false;
          if (action.payload && typeof action.payload === 'object') {
            const error = action.payload as AuthState;
            state.error = error.error;
          }
        },
      );
  },
});

export const selectAuth = (state: RootState) => state.auth;

export const useAuth = () => useSelector(selectAuth);
