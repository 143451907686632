import { useCallback, useEffect, useState } from 'react';

import {
  useIdleSince,
  useIsTabActive,
  useIsUserActive,
  useMutateIsTabActive,
  useMutateIsUserActive,
} from '../store/session/session.slice';

const DEFAULT_IDLE_TIMEOUT = 1000 * 60 * 1; // 1 minute

const throttle = <T extends (...args: unknown[]) => unknown>(
  func: T,
  limit: number,
): ((...args: Parameters<T>) => void) => {
  let lastFunc: NodeJS.Timeout | undefined;
  let lastRan: number | undefined;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    if (!lastRan) {
      func.apply(this, args);
      lastRan = Date.now();
    } else {
      if (lastFunc) {
        clearTimeout(lastFunc);
      }
      lastFunc = setTimeout(
        () => {
          if (Date.now() - (lastRan ?? 0) >= limit) {
            func.apply(this, args);
            lastRan = Date.now();
          }
        },
        limit - (Date.now() - lastRan),
      );
    }
  };
};

const useSessionManagement = (idleTimeout = DEFAULT_IDLE_TIMEOUT) => {
  const idleSince = useIdleSince();
  const isUserActive = useIsUserActive();
  const setIsUserActive = useMutateIsUserActive();
  const isTabActive = useIsTabActive();
  const setIsTabActive = useMutateIsTabActive();

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const resetSessionTimer = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }

    if (!isUserActive) {
      setIsUserActive(true);
    }

    setTimer(
      setTimeout(() => {
        setIsUserActive(false);
      }, idleTimeout),
    );
  }, [idleTimeout, isUserActive, setIsUserActive, timer]);

  // Throttle the resetTimer function to prevent it from being called too often
  const throttledResetTimer = throttle(resetSessionTimer, 1000);

  useEffect(() => {
    // Handle tab visibility state
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        resetSessionTimer();
        setIsTabActive(true);
      } else {
        setIsTabActive(false);
      }
    };

    // Tab visibility event listener
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      // Remove tab visibility listener
      document.removeEventListener('visibilitychange', handleVisibilityChange);

      // Clear the timer
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [
    idleTimeout,
    resetSessionTimer,
    setIsTabActive,
    throttledResetTimer,
    timer,
  ]);

  return { idleSince, isUserActive, isTabActive, resetSessionTimer };
};

export default useSessionManagement;
