import type {
  BaseQueryApi,
  BaseQueryArg,
  BaseQueryExtraOptions,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { type RetryOptions } from '@reduxjs/toolkit/dist/query/retry';
import { retry } from '@reduxjs/toolkit/query';
import {
  type BaseQueryFn,
  type FetchArgs,
  fetchBaseQuery,
  type FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import appConfig from '../../../config/app.config';
import type { RootState } from '../../store/store';
import exponentialBackoff from '../../utils/exponential-backoff';

const NON_RETRYABLE_ERROR_CODES = [
  400, // Bad request
  401, // Unauthorized
  403, // Forbidden
  404, // Not found
  429, // Too many requests
  500, // Internal server error
  502, // Bad gateway
  503, // Service unavailable
];

/**
 * Copied from @reduxjs/toolkit/src/query/retry
 */
type RetryConditionFunction = (
  error: FetchBaseQueryError,
  args: BaseQueryArg<BaseQueryFn>,
  extraArgs: {
    attempt: number;
    baseQueryApi: BaseQueryApi;
    extraOptions: BaseQueryExtraOptions<BaseQueryFn> & RetryOptions;
  },
) => boolean;

/**
 * Implements a backoff timer using exponential delay and jitter.
 *
 * @param attempt The current attempt number.
 * @param _ Unused.
 * @returns A promise that resolves after the calculated backoff time.
 */
async function backoff(attempt: number, _: unknown) {
  const timeout = exponentialBackoff({
    attempt,
    maxRetries: appConfig.maxApiRetryAttempts,
  });
  await new Promise(resolve =>
    setTimeout((res: unknown) => resolve(res), timeout),
  );
}

/**
 * Implements a retry condition function that retries on all errors except for
 * those with a status code in NON_RETRYABLE_ERROR_CODES.
 */
const retryCondition: RetryConditionFunction = (error, _args, { attempt }) => {
  // fail if we've exceeded the max number of retries
  if (attempt >= appConfig.maxApiRetryAttempts) {
    return false;
  }

  // If error is numeric then it is an http status code
  if (typeof error.status === 'number') {
    return !NON_RETRYABLE_ERROR_CODES.includes(error.status);
  }

  // Otherwise retry
  return true;
};

/**
 * A base query that retries and implements authentication.
 */
export const retryBaseQuery = retry(
  fetchBaseQuery({
    baseUrl: appConfig.lambdaApiBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  { retryCondition, backoff },
);

export const lambdaBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // TODO: check if the token is expired, and if our refresh token is expired

  // TODO: attempt to get a new token

  // TODO: if we have a new token, retry the request

  return retryBaseQuery(args, api, extraOptions);
};

export default lambdaBaseQuery;
