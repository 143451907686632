import { Value } from '@sinclair/typebox/value';

import { JobSchedule } from '../schema/job-schedule.schema';

export const createJobSchedule = (schedule?: JobSchedule): JobSchedule => {
  return {
    ...Value.Create(JobSchedule),
    ...schedule,
  };
};
