import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import createEntityHooks from '../utils/create-entity-hooks';

interface AppState {
  companyId: string;
  showEmailStatusDialog: boolean;
}

const initialState: AppState = {
  companyId: '',
  showEmailStatusDialog:
    typeof window !== 'undefined'
      ? localStorage.getItem('showEmailStatusDialog') === 'true'
      : false,
};

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setCompanyId(state, action: PayloadAction<string>) {
      state.companyId = action.payload;
    },
    setShowEmailStatusDialog(state, action: PayloadAction<boolean>) {
      state.showEmailStatusDialog = action.payload;
      if (typeof window !== 'undefined') {
        localStorage.setItem(
          'showEmailStatusDialog',
          action.payload.toString(),
        );
      }
    },
  },
});

const { actions } = appStateSlice;

const [useGetCompanyId, useMutateCompanyId] = createEntityHooks(
  appStateSlice,
  'companyId',
  actions.setCompanyId,
);

const [useGetShowEmailStatusDialog, useMutateShowEmailStatusDialog] =
  createEntityHooks(
    appStateSlice,
    'showEmailStatusDialog',
    actions.setShowEmailStatusDialog,
  );

export {
  useGetCompanyId,
  useGetShowEmailStatusDialog,
  useMutateCompanyId,
  useMutateShowEmailStatusDialog,
};
