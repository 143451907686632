import { createApi } from '@reduxjs/toolkit/query/react';

import engageBaseQuery from './engage-base-query';

/**
 * Engage API
 *
 * Provides a base api object to be extended using `injectEndpoints`.
 */
export const engageApi = createApi({
  reducerPath: 'engageApi',
  baseQuery: engageBaseQuery,
  endpoints: () => ({}),
});
