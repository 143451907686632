/* eslint-disable @typescript-eslint/no-redeclare */
// enum emulation

export const EmailStatus = {
  SENT: 'SENT',
  DELIVERY: 'DELIVERY',
  BOUNCE: 'BOUNCE',
  TEMP_BOUNCE: 'TEMP_BOUNCE',
  OPEN: 'OPEN',
  COMPLAINT: 'COMPLAINT',
} as const;
export type EmailStatus = (typeof EmailStatus)[keyof typeof EmailStatus];
