/* eslint-disable @typescript-eslint/no-redeclare */
export type DataExtractorFieldDataType =
  (typeof DataExtractorFieldDataType)[keyof typeof DataExtractorFieldDataType];
export const DataExtractorFieldDataType = {
  STRING: 'STRING',
  NUMERIC: 'NUMERIC',
  BOOLEAN: 'BOOLEAN',
  JSON: 'JSON',
  TIMESTAMP: 'TIMESTAMP',
} as const;
