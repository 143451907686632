/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

export type JobStatus = Static<typeof JobStatus>;
export const JobStatus = Type.Union([
  Type.Literal('NOT_RUNNING'),
  Type.Literal('RUNNING'),
  Type.Literal('SUCCEEDED'),
  Type.Literal('FAILED'),
  Type.Literal('TIMED_OUT'),
  Type.Literal('ABORTED'),
]);
