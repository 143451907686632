/* eslint-disable @typescript-eslint/no-redeclare */

export type FileUploadStatus =
  (typeof FileUploadStatus)[keyof typeof FileUploadStatus];
export const FileUploadStatus = {
  COMPLETED: 'COMPLETED',
  EXPIRED: 'EXPIRED',
  FAILED: 'FAILED',
  INVALID: 'INVALID',
  PENDING: 'PENDING',
} as const;
