export const ChartType = {
  LINE: 'line',
  BAR: 'bar',
  HORIZONTAL_BAR: 'horizontalBar',
  PIE: 'pie',
  OUTLABELED: 'outlabeledPie',
  RADIAL_GAUGE: 'radialGauge',
};

const safely = (text: string): string => {
  return `##${text.replace(/\n/g, '').trim()}##`;
};

export const OPTIONS_NUMBER_FORMATS = {
  NONE: {
    id: 'NONE',
    callback: undefined,
  },

  THOUSAND_SEPARATOR: {
    id: 'THOUSAND_SEPARATOR',
    callback: safely(`function (context) {
      if(context === undefined || context === null ) {
        return;
      }
      let val = context;
      if(typeof context === 'object' && context.dataset) {
        val = context.dataset.data[context.dataIndex];
      }
      return !isNaN(val) ? Number(val).toLocaleString('en') : val;
    }`),
  },

  CURRENCY: {
    id: 'CURRENCY',
    callback: safely(`function (context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = context;
      if(typeof context === 'object' && context.dataset) {
        val = context.dataset.data[context.dataIndex];
      }
      const formattedValue = !isNaN(val) ? '$' + Number(val).toLocaleString('en') : val;

      return formattedValue.startsWith('$' + '-')
            ? formattedValue.replaceAll('$' + '-', '-' + '$')
            : formattedValue;
    }`),
  },

  CURRENCY_ACCOUNTING: {
    id: 'CURRENCY_ACCOUNTING',
    callback: safely(`function (context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = context;
      if(typeof context === 'object' && context.dataset) {
        val = context.dataset.data[context.dataIndex];
      }
      const formattedValue = !isNaN(val) ? '$' + Number(val).toLocaleString('en') : val;

      return formattedValue.startsWith('$' + '-')
            ? formattedValue.replaceAll('$' + '-', '(' + '$') + ')'
            : formattedValue;
    }`),
  },

  CURRENCY_THOUSAND_BASED: {
    id: 'CURRENCY_THOUSAND_BASED',
    callback: safely(`function (context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = context;
      if(typeof context === 'object' && context.dataset) {
        val = context.dataset.data[context.dataIndex];
      }

      const formattedValue = !isNaN(val)
        ? Math.abs(Number(val)) >= 1000000 ? '$' + (Number(val) / 1000000).toFixed(1) + 'M'
          : Math.abs(Number(val)) >= 1000 ? '$' + (Number(val) / 1000).toFixed(1) + 'K'
          : '$' + Number(val).toFixed(1)
        : val;

      return formattedValue.startsWith('$' + '-')
          ? formattedValue.replaceAll('$' + '-', '-' + '$')
          : formattedValue;
    }`),
  },

  CURRENCY_ACCOUNTING_THOUSAND_BASED: {
    id: 'CURRENCY_ACCOUNTING_THOUSAND_BASED',
    callback: safely(`function (context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = context;
      if(typeof context === 'object' && context.dataset) {
        val = context.dataset.data[context.dataIndex];
      }
      const formattedValue = !isNaN(val)
        ? Math.abs(Number(val)) >= 1000000 ? '$' + (Number(val) / 1000000).toFixed(1) + 'M'
          : Math.abs(Number(val)) >= 1000 ? '$' + (Number(val) / 1000).toFixed(1) + 'K'
          : '$' + Number(val).toFixed(1)
        : val;

      return formattedValue.startsWith('$' + '-')
          ? formattedValue.replaceAll('$' + '-', '(' + '$') + ')'
          : formattedValue;
    }`),
  },

  PERCENTAGE: {
    id: 'PERCENTAGE',
    callback: safely(`function (context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = context;
      if(typeof context === 'object' && context.dataset) {
        val = context.dataset.data[context.dataIndex];
      }

      return !isNaN(val) ? Number(val) / 100 + '%' : val;
    }`),
  },

  PERCENTAGE_NO_DIVIDED: {
    id: 'PERCENTAGE_NO_DIVIDED',
    callback: safely(`function (context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = context;
      if(typeof context === 'object' && context.dataset) {
        val = context.dataset.data[context.dataIndex];
      }

      return !isNaN(val) ? Number(val) + '%' : val;
    }`),
  },

  PERCENTAGE_ACCOUNTING: {
    id: 'PERCENTAGE_ACCOUNTING',
    callback: safely(`function (context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = context;
      if(typeof context === 'object' && context.dataset) {
        val = context.dataset.data[context.dataIndex];
      }

      const formattedValue = !isNaN(val) ? Number(val) / 100 + '%' : val;

      if (formattedValue.startsWith('-')) {
        return '(' + formattedValue.replace('-', '') + ')';
      } else {
        return formattedValue;
      }
    }`),
  },
};

export const CURRENCY = {
  USD: { code: 'USD', symbol: '$' },
  AUD: { code: 'AUD', symbol: 'AU$' },
  NZD: { code: 'NZD', symbol: 'NZ$' },
  GBP: { code: 'GBP', symbol: '£' },
};

export const CURRENCY_CODES: string[] = [
  CURRENCY.USD.code,
  CURRENCY.AUD.code,
  CURRENCY.NZD.code,
  CURRENCY.GBP.code,
];
