/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

import { TaskSchema } from '../../task/schema/index';
import { JobInputParamsSchema } from './job-input-params.schema';
import { JobScheduleParams } from './job-schedule-params.schema';
import { JobStatus } from './job-status.schema';

export type JobSchema = Static<typeof JobSchema>;
export const JobSchema = Type.Object({
  jobId: Type.String(),
  companyId: Type.String(),
  userId: Type.String(),
  taskId: Type.String(),
  name: Type.Optional(Type.String()),
  runFrom: Type.Optional(Type.String()),
  runUntil: Type.Optional(Type.String()),
  timezone: Type.Optional(Type.String()),
  inputParams: JobInputParamsSchema,
  scheduleParams: JobScheduleParams,
  status: JobStatus,
  task: TaskSchema,
  runTimes: Type.Number(),
  createdAt: Type.Date(),
  updatedAt: Type.Date(),
});
