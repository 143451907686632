/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

import { DataExtractor } from './data-extractor.schema';

export type JsonRestApiDataExtractor = Static<typeof JsonRestApiDataExtractor>;

export const JsonRestApiDataExtractor = Type.Intersect([
  Type.Object({
    sourceType: Type.Literal('JSON_REST_API'),
  }),
  Type.Pick(DataExtractor, [
    'dataExtractorId',
    'companyId',
    'userId',
    'templateId',
    'code',
    'name',
    'executionOrder',
    'isHidden',
    'preStageScripts',
    'postStageScripts',
    'defaultOrder',
    'defaultOrderDirection',
    'previousExtractorsRequired',
    'remotePath',
    'jsonStartingPath',
  ]),
]);
