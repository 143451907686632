import type { ActionExecutionInfo } from '@rego-engage/engage-types';

import { buildURL } from '../../../helpers/build-headers';
import { lambdaApi } from '../lambda.api';
import type { ActionExecutionInfoQueryParams } from './action-execution-info.interface';

const actionExecutionInfoPath = 'get-action-execution-info';

const actionExecutionInfoApi = lambdaApi
  .enhanceEndpoints({
    addTagTypes: ['Lambda'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getActionExecutionInfo: builder.query<
        ActionExecutionInfo,
        ActionExecutionInfoQueryParams
      >({
        query: params => ({
          url: buildURL({
            path: `${actionExecutionInfoPath}\\${params.executionId}`,
          }),
          method: 'GET',
        }),
        providesTags: ['Lambda'],
        transformResponse: (baseQueryReturnValue: ActionExecutionInfo) => ({
          ...baseQueryReturnValue,
        }),
      }),
    }),
  });

export const useGetActionExecutionInfo =
  actionExecutionInfoApi.useLazyGetActionExecutionInfoQuery;
