import { createApi } from '@reduxjs/toolkit/query/react';

import lambdaBaseQuery from './lambda-base-query';

/**
 * Engage API
 *
 * Provides a base api object to be extended using `injectEndpoints`.
 */
export const lambdaApi = createApi({
  reducerPath: 'lambdaApi',
  baseQuery: lambdaBaseQuery,
  endpoints: () => ({}),
});
