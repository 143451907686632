import Button from '@mui/joy/Button';
import Container from '@mui/joy/Container';
import Typography from '@mui/joy/Typography';

import logger from '../../services/logger';

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: ErrorFallbackProps) => {
  logger.error({ error }, 'ErrorBoundary caught an error');

  return (
    <Container
      maxWidth={false}
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'background.default',
      }}
    >
      <Typography level="h4" gutterBottom>
        Oops! Something went wrong.
      </Typography>
      <Typography level="body-lg" gutterBottom>
        We&apos;re sorry for the inconvenience. Please try again later.
      </Typography>
      <Button variant="outlined" color="primary" onClick={resetErrorBoundary}>
        Return Home
      </Button>
    </Container>
  );
};
