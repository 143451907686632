/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

export type Action = Static<typeof Action>;
export const Action = Type.Object({
  actionId: Type.String(),
  type: Type.String(),
  taskActionId: Type.String(),
  payload: Type.Optional(Type.Record(Type.String(), Type.Unknown())),
});

export type TaskDefinition = Static<typeof TaskDefinition>;
export const TaskDefinition = Type.Object({
  targetArn: Type.String(),
  roleArn: Type.String(),
  actions: Type.Array(Action),
  scheduler: Type.String(),
});

export type TaskSchema = Static<typeof TaskSchema>;
export const TaskSchema = Type.Object({
  taskId: Type.String(),
  name: Type.String(),
  description: Type.String(),
  definition: TaskDefinition,
  createdAt: Type.Date(),
  updatedAt: Type.Date(),
});

export type CreateTaskSchema = Static<typeof CreateTaskSchema>;
export const CreateTaskSchema = Type.Pick(
  TaskSchema,
  Type.Union([
    Type.Literal('name'),
    Type.Literal('description'),
    Type.Literal('definition'),
  ]),
);

export type CreateTaskParams = Static<typeof CreateTaskParams>;
export const CreateTaskParams = Type.Object({
  body: CreateTaskSchema,
});

export type GetTaskParams = Static<typeof GetTaskParams>;
export const GetTaskParams = Type.Object({
  taskId: Type.String(),
});

export type UpdateTaskSchema = Static<typeof UpdateTaskSchema>;
export const UpdateTaskSchema = Type.Partial(CreateTaskSchema);

export type UpdateTaskParams = Static<typeof UpdateTaskParams>;
export const UpdateTaskParams = Type.Object({
  taskId: Type.String(),
  body: UpdateTaskSchema,
});
