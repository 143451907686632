/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

import { JobSchedule } from './job-schedule.schema';
import { JobScheduleType } from './job-schedule-type.schema';

export type JobScheduleParams = Static<typeof JobScheduleParams>;
export const JobScheduleParams = Type.Object({
  type: JobScheduleType,
  value: Type.Union([Type.String(), JobSchedule]),
});
