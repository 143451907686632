import Lottie from 'lottie-react';

import loadingAnimation from '../../../public/assets/animations/loading.lottie.json';

const Loader = (props: { label?: string }) => {
  return (
    <div
      className={`w-full h-full fixed inset-0 flex flex-col items-center justify-center`}
    >
      <div className="h-64 w-64">
        <Lottie animationData={loadingAnimation} loop={true} />
      </div>
      {props.label && <p className="text-slate-800 font-thin">{props.label}</p>}
    </div>
  );
};

export default Loader;
