import {
  BarChartSquare02,
  Briefcase02,
  Building05,
  CalendarDate,
  Edit05,
  FileSearch02,
  LayersThree01,
  Mail01,
  Users01,
  UserSquare,
} from '@untitled-ui/icons-react';

import { RouteGroup } from './types';

export const routes: RouteGroup[] = [
  {
    mainRoute: {
      path: '/',
      name: 'Login',
      isPublic: true,
      drawable: false,
      scope: 'all',
    },
    routes: [],
  },
  {
    mainRoute: {
      path: '/auth',
      name: 'Auth',
      isPublic: true,
      drawable: false,
      scope: 'all',
    },
    routes: [
      { path: '/auth/login', name: 'Login', isPublic: true, drawable: false },
      {
        path: '/auth/update-pass',
        name: 'Update Password',
        isPublic: true,
        drawable: false,
      },
      {
        path: '/auth/forgot-pass/request',
        name: 'Forgot Password',
        isPublic: true,
        drawable: false,
      },
      {
        path: '/auth/forgot-pass/confirm',
        name: 'Forgot Password Confirm',
        isPublic: true,
        drawable: false,
      },
      { path: '/auth/otp', name: 'OTP', isPublic: true, drawable: false },
      {
        path: '/auth/reset-pass',
        name: 'Reset Password',
        isPublic: true,
        drawable: false,
      },
    ],
  },
  {
    mainRoute: {
      path: '/dashboard',
      name: 'Dashboard',
      icon: <BarChartSquare02 />,
      drawable: true,
      main: true,
    },
    routes: [],
  },
  {
    mainRoute: {
      path: '/mailer',
      name: 'Mailer',
      icon: <Mail01 />,
      drawable: true,
      main: true,
      isMailerCheck: true,
    },
    routes: [
      { path: '/mailer/email', name: 'Email' },
      { path: '/mailer/preview', name: 'Preview' },
    ],
  },
  {
    mainRoute: {
      path: '/reports',
      name: 'Schedule',
      icon: <CalendarDate />,
      drawable: true,
      main: true,
    },
    routes: [
      { path: '/reports', name: 'Calendar' },
      { path: '/reports/list', name: 'List' },
    ],
  },
  {
    mainRoute: {
      path: '/templates',
      name: 'Templates',
      icon: <LayersThree01 />,
      drawable: true,
      main: true,
    },
    routes: [
      { path: '/templates/email', name: 'Mailing' },
      { path: '/templates/data-extractor', name: 'Data Extractors' },
      { path: '/templates/filter-parameter', name: 'Filters & Parameters' },
      { path: '/templates/design', name: 'Design' },
    ],
  },
  {
    mainRoute: {
      path: '/job-execution-log',
      name: 'Job Status',
      icon: <FileSearch02 />,
      drawable: true,
      main: true,
    },
    routes: [],
  },
  {
    mainRoute: {
      path: '/contacts',
      name: 'Contacts',
      icon: <UserSquare />,
      drawable: true,
      scope: 'manage:contact',
      main: true,
    },
    routes: [
      {
        path: '/contacts',
        name: 'Contacts',
        scope: 'manage:contact',
      },
      {
        path: '/contact-lists',
        name: 'Contact Lists',
        scope: 'manage:contact-list',
      },
    ],
  },
  {
    mainRoute: {
      path: '/details',
      name: 'My Details',
      icon: <Edit05 />,
      drawable: true,
      main: false,
    },
    routes: [
      { path: '/details', name: 'My Details' },
      { path: '/details/password', name: 'Password' },
      { path: '/details/security-access', name: 'Security & Access' },
      { path: '/details/api-key', name: 'API Key' },
    ],
  },
  {
    mainRoute: {
      path: '/company-settings',
      name: 'Company Settings',
      icon: <Building05 />,
      drawable: true,
      main: false,
      scope: 'manage:company',
    },
    routes: [
      {
        path: '/company-settings',
        name: 'Company Settings',
        scope: 'manage:company',
      },
      {
        path: '/company-settings/apptio',
        name: 'Apptio Environments',
        scope: 'manage:apptio-environment',
      },
      {
        path: '/company-settings/branding',
        name: 'Branding',
        scope: 'manage:company',
      },
    ],
  },
  {
    mainRoute: {
      path: '/users',
      name: 'Users',
      icon: <Users01 />,
      drawable: true,
      main: false,
      scope: 'manage:user',
    },
    routes: [],
  },
  {
    mainRoute: {
      path: '/companies',
      name: 'Companies',
      icon: <Briefcase02 />,
      drawable: true,
      main: false,
      scope: 'switch:company',
    },
    routes: [
      { path: '/companies', name: 'Companies' },
      {
        path: '/companies/tasks',
        name: 'Tasks',
        scope: 'manage:task',
      },
    ],
  },
];
