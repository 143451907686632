/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

import { DataExtractor } from './data-extractor.schema';

export type StagingDataExtractor = Static<typeof StagingDataExtractor>;
export const StagingDataExtractor = Type.Intersect([
  Type.Object({
    sourceType: Type.Literal('STAGING'),
  }),
  Type.Pick(DataExtractor, [
    'dataExtractorId',
    'companyId',
    'userId',
    'templateId',
    'code',
    'name',
    'executionOrder',
    'isHidden',
    'preStageScripts',
    'postStageScripts',
    'defaultOrder',
    'defaultOrderDirection',
    'previousExtractorsRequired',
    'sqlQuery',
  ]),
]);
