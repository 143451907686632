import { Hub } from 'aws-amplify/utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import appConfig from '../../config/app.config';
import logger from '../services/logger';
import { refreshSession } from '../store/auth/actions';
import { useAuth } from '../store/auth/auth.slice';
import { useAppDispatch } from '../store/store';

interface AuthProviderProps {
  loader?: React.ReactNode;
  children?: React.ReactNode;
}

export const AuthProvider = ({ loader, children }: AuthProviderProps) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const {
    accessTokenExpiration,
    idTokenExpiration,
    isAuth,
    loading,
    isNewPasswordRequired,
    isPasswordResetRequired,
  } = useAuth();

  // Handle password update requirement
  useEffect(() => {
    logger.debug(
      '%s (Auth): Checking password update requirement',
      AuthProvider.name,
    );

    if (isAuth || loading) {
      return;
    }
    if (isNewPasswordRequired && router.pathname !== '/auth/update-pass') {
      logger.debug(
        '%s (Auth): Redirecting to update password',
        AuthProvider.name,
      );
      router
        .push('/auth/update-pass')
        .catch((err: unknown) => logger.error({ err }));
    }

    if (isPasswordResetRequired && router.pathname !== '/auth/reset-pass') {
      logger.debug(
        '%s (Auth): Redirecting to reset password',
        AuthProvider.name,
      );
      router
        .push('/auth/reset-pass')
        .catch((err: unknown) => logger.error({ err }));
    }
  }, [
    isAuth,
    router,
    loading,
    isNewPasswordRequired,
    isPasswordResetRequired,
    dispatch,
  ]);

  useEffect(() => {
    const removeListener = Hub.listen('auth', ({ payload }) => {
      logger.debug(
        { payload },
        '%s (Hub.listen): %s',
        AuthProvider.name,
        payload.message,
      );

      // Handle auth events
      switch (payload.event) {
        case 'signedOut':
          void router.push('/').catch(err => {
            logger.error(err);
          });
          break;
        case 'signedIn':
          void router.push('/dashboard').catch(err => {
            logger.error(err);
          });
          break;
        default:
          break;
      }
    });

    // Remove the listener on unmount
    return () => {
      logger.debug('%s (Hub): Removing Hub listener', AuthProvider.name);
      removeListener();
    };
  }, [dispatch, router]);

  // Attempt to refresh session on page load
  useEffect(() => {
    logger.debug('%s (Load): Refreshing Session', AuthProvider.name);

    void dispatch(refreshSession({}));
  }, [dispatch]);

  /**
   * Refresh session before token expiration
   */
  useEffect(() => {
    if (isAuth && accessTokenExpiration && idTokenExpiration) {
      let timeoutId: NodeJS.Timeout | null = null;

      // Calculate the next refresh time
      const nextRefresh = Math.min(
        accessTokenExpiration - Date.now() - appConfig.beforeTokenExpiration,
        idTokenExpiration - Date.now() - appConfig.beforeTokenExpiration,
      );

      // If the next refresh time is very short, refresh immediately
      if (nextRefresh <= 0) {
        logger.debug('%s (Timer): Immediate token refresh', AuthProvider.name);
        void dispatch(refreshSession({ quietMode: true }));
      }

      // Otherwise, set a timer to refresh at the next refresh time
      else {
        timeoutId = setTimeout(() => {
          logger.debug('%s (Timer): Refreshing Tokens', AuthProvider.name);
          void dispatch(refreshSession({ quietMode: true }));
        }, nextRefresh);
      }

      // Clear the timer on unmount
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }
    // Do nothing if the tokens are not set
  }, [accessTokenExpiration, dispatch, idTokenExpiration, isAuth]);

  return <>{!loading ? children : loader}</>;
};

export default AuthProvider;
