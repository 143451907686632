/* eslint-disable @typescript-eslint/no-redeclare */
import { Static, Type } from '@sinclair/typebox';

import { ScheduleValue } from './schedule-value.schema';

export type JobSchedule = Static<typeof JobSchedule>;
export const JobSchedule = Type.Object({
  minutes: ScheduleValue,
  hours: ScheduleValue,
  dayOfMonth: ScheduleValue,
  month: ScheduleValue,
  dayOfWeek: Type.Array(Type.Number(), { default: [] }),
  year: ScheduleValue,
  dayOfWeekInstance: Type.Optional(Type.Number()),
  nearestWeekday: Type.Optional(Type.Boolean()),
  lastDayOfMonth: Type.Optional(Type.Boolean()),
  lastInstanceOfDayOfWeek: Type.Optional(Type.Boolean()),
});
