import colors from '@mui/joy/colors';
import { experimental_extendTheme as materialExtendTheme } from '@mui/material/styles';

export const theme = materialExtendTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 'unset',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '--FormLabel-alignSelf': 'flex-start',
          '--Form:abel-fontSize': 'var(--joy-fontSize-sm)',
          '--FormLabel-lineHeight': 'var(--joy-lineHeight-sm)',
          '--FormLabel-margin': '0 0 0.375rem 0',
          '--FormHelperText-fontSize': 'var(--joy-fontSize-sm)',
          '--FormHelperText-lineHeight': 'var(--joy-lineHeight-sm)',
          '--FormHelperText-margin': '0.375rem 0 0 0',
          display: 'flex',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--joy-fontFamily-body)',
          fontSize: 'var(--FormLabel-fontSize, var(--joy-fontSize-sm))',
          fontWeight: 'var(--joy-fontWeight-md)',
          lineHeight: 'var(--FormLabel-lineHeight, var(--joy-lineHeight-sm))',
          color: 'var(--FormLabel-color, var(--joy-palette-text-primary))',
          margin: 'var(--FormLabel-margin, 0)',
          letterSpacing: 'inherit',
          '&.MuiInputLabel-root.Mui-error': {
            color: 'unset',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          gap: '2px',
          whiteSpace: 'inherit',
          maxWidth: 'unset',
          position: 'relative',
          left: 'unset',
          top: 'unset',
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          transformOrigin: 'unset',
          transform: 'unset',
          transition: 'unset',
          '&.Mui-focused': {
            color: 'unset',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          gap: '2px',
          // fontFamily: 'var(--joy-fontFamily-body)',
          // fontSize: 'var(--FormHelperText-fontSize, var(--joy-fontSize-sm))',
          // lineHeight:
          //   'var(--FormHelperText-lineHeight, var(--joy-lineHeight-sm))',
          color:
            'var(--FormHelperText-color, var(--joy-palette-text-secondary))',
          margin: 'var(--FormHelperText-margin, 0)',
          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          fontSize: '0.75rem',
          lineHeight: 1.66,
          letterSpacing: '0.03333em',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '--Input-radius': 'var(--joy-radius-sm)',
          '--Input-gap': '0.5em',
          '--Input-placeholderColor': 'inherit',
          '--Input-placeholderOpacity': 0.64,
          '--Input-decoratorColor': 'var(--joy-palette-text-icon)',
          '--Input-focused': 0,
          '--Input-focusedThickness': 'var(--joy-focus-thickness)',
          '--Input-focusedHighlight': 'var(--joy-palette-primary-500)',
          '--Input-minHeight': '2.25em',
          '--Input-paddingInline': '0.75em',
          '--Input-decoratorChildHeight':
            'min(1.75rem, var(--Input-minHeight))',
          '--Input-fontSize': 'var(--joy-fontSize-xl2)',
          '--Input-decoratorChildOffset':
            'min(calc(var(--Input-paddingInline) - (var(--Input-minHeight) - 2 * var(--variant-borderWidth, 0px) - var(--Input-decoratorChildHeight)) / 2), var(--Input-paddingInline))',
          '--_Input-paddingBlock':
            'max((var(--Input-minHeight) - 2 * var(--variant-borderWidth, 0px) - var(--Input-decoratorChildHeight)) / 2, 0px)',
          '--Input-decoratorChildRadius':
            'max(var(--Input-radius) - var(--variant-borderWidth, 0px) - var(--_Input-paddingBlock), min(var(--_Input-paddingBlock) + var(--variant-borderWidth, 0px), var(--Input-radius) / 2))',
          '--variant-borderWidth': '1px',
          boxShadow:
            'var(--joy-shadowRing, 0 0 #000),0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))',
          minHeight: '2.25em',
          paddingInline: '0.75em',
          borderRadius: 'var(--joy-radius-sm)',
          fontFamily:
            'var(--joy-fontFamily-body, "Inter", var(--joy-fontFamily-fallback, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"))',
          fontSize: 'var(--joy-fontSize-md, 1rem)',
          lineHeight: 'var(--joy-lineHeight-md, 1.5rem)',
          color:
            'var(--variant-outlinedColor, var(--joy-palette-neutral-outlinedColor, var(--joy-palette-neutral-700, #32383E)))',
          border: '1px solid',
          borderColor:
            'var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))',
          backgroundColor: 'var(--joy-palette-background-surface)',
          '&:not([data-skip-inverted-colors])': {
            '--Input-focusedHighlight':
              'var(--_Input-focusedHighlight, var(--joy-palette-focusVisible))',
          },
          '&:focus-within::before': {
            '--Input-focused': 1,
          },
          '&::before': {
            boxSizing: 'border-box',
            content: '""',
            display: 'block',
            position: 'absolute',
            pointerEvents: 'none',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
            borderRadius: 'inherit',
            margin: 'calc(var(--variant-borderWidth, 0px) * -1)',
            boxShadow:
              'var(--Input-focusedInset, inset) 0 0 0 calc(var(--Input-focused) * var(--Input-focusedThickness)) var(--Input-focusedHighlight)',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            // borderColor:
            //   'var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))',
            // borderColor:
            //   'var(--variant-outlinedBorderHover, var(--joy-palette-neutral-outlinedBorderHover, var(--joy-palette-neutral-500, #7D899C)))',
          },
          '&.Mui-error': {
            borderColor:
              'var(--variant-outlinedBorder, var(--joy-palette-danger-outlinedBorder, var(--joy-palette-danger-300, #F09898)))',
          },
        },
        input: {
          padding: 0,
          'label[data-shrink=false]+.MuiInputBase-formControl &::placeholder': {
            color:
              'var(--variant-outlinedPlaceholderColor, var(--joy-palette-neutral-outlinedPlaceholderColor, var(--joy-palette-neutral-500, #7D899C)))',
            opacity:
              'var(--variant-outlinedPlaceholderOpacity, var(--joy-palette-neutral-outlinedPlaceholderOpacity, 0.64))!important',
          },
        },
      },
    },
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          // light: '#EDF5FD',
          main: colors.blue[500],
          // dark: '#051423',
          ...colors.blue,
        },
        // secondary: {
        //   // light: '#EDF5FD',
        //   main: '#97C3F0',
        //   // dark: '#051423',
        //   ...colors.blue,
        // },
        secondary: {
          main: colors.grey[300],
          ...colors.grey,
        },
        common: {
          black: '#000',
          white: '#fff',
        },
        text: {
          primary: colors.grey[800],
          secondary: colors.grey[700],
        },
        grey: {
          ...colors.grey,
        },

        error: {
          main: colors.red[500],
          ...colors.red,
        },
        warning: {
          main: colors.yellow[500],
          ...colors.yellow,
        },
        info: {
          main: colors.blue[500],
          ...colors.blue,
        },
        success: {
          main: colors.green[500],
          ...colors.green,
        },
      },
    },
  },
});
