import './globals.css';

import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { setupHandleBarsFunctions } from '@rego/email-designer';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import AuthProvider from '../common/auth/auth-provider';
import ProfileProvider from '../common/auth/profile-provider';
import { ErrorFallback } from '../common/components/error/error-fallback';
import SiteMeta from '../common/components/meta/site-meta';
import { ToastProvider } from '../common/components/notification';
import Loader from '../common/components/shared/loader';
import RouteGuard from '../common/route/route-guard';
import logger from '../common/services/logger';
import { StoreProvider } from '../common/store/store-provider';
import { theme as joyTheme } from '../common/themes/joy';
import { theme as muiTheme } from '../common/themes/mui';
import safely from '../common/utils/safely';
import { StartTypeEngine } from '../common/validation/typebox/formats';

StartTypeEngine();
setupHandleBarsFunctions();

export default function App({ Component, pageProps }: AppProps<object>) {
  logger.info('🚀  Starting frontend...');
  const router = useRouter();
  return (
    <React.Fragment>
      <SiteMeta />
      <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: muiTheme }}>
        <JoyCssVarsProvider theme={joyTheme}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <StoreProvider>
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={safely(async () => {
                  await router.push('/dashboard');
                })}
              >
                <AuthProvider>
                  <ProfileProvider loader={<Loader label="Loading..." />}>
                    <ToastProvider />

                    <RouteGuard pageComponent={<Component {...pageProps} />} />
                  </ProfileProvider>
                </AuthProvider>
              </ErrorBoundary>
            </StoreProvider>
          </LocalizationProvider>
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    </React.Fragment>
  );
}
