import Head from 'next/head';

const SiteMeta = () => {
  return (
    <Head>
      <title>Rego Engage</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />

      {/* <!-- General --> */}
      <meta name="application-name" content="Rego Engage" />

      <link rel="shortcut icon" href="/assets/favicon/favicon.ico" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/assets/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/assets/favicon/favicon-16x16.png"
      />

      {/* <!-- Apple --> */}
      <meta name="apple-mobile-web-app-title" content="My Site" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/assets/favicon/apple-touch-icon.png"
      />

      {/* <!-- Android --> */}
      <meta name="mobile-web-app-capable" content="yes" />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/assets/favicon/android-chrome-192x192.png"
      />
    </Head>
  );
};

export default SiteMeta;
